import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {RootState} from '../rootReducer';
import {setAccount, createAccount} from './reducer';
//import history from '../history';
import CloudAPI from '../CloudAPI';
import {PropsFromUIHelpers, withUIHelpers,} from '../components/hocs';


const mapState = (state:RootState) => ({});
const mapDispatch = {setAccount};
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

interface State {}

class LogoutPage extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        localStorage.clear();
        this.props.setAccount(createAccount());
        CloudAPI.setCredit('', '');
        //history.replace('/signin');
        this.props.navigate('/signin');
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>Signing out...</div>
        );
    }
}

export default withUIHelpers(connector(LogoutPage));
