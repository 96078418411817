import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {Form, Button, } from 'react-bootstrap';
import {Link, } from 'react-router-dom';
import CloudAPI from '../CloudAPI';
import Consts from '../Consts';
import {PropsFromUIHelpers, withUIHelpers,} from '../components/hocs';
import {DialogBusyWait, DialogAlert } from '../components/dialogs';
//import history from '../history';
import {setAccount, Account} from './reducer';
import {RootState} from '../rootReducer';


const mapState = (state:RootState) => ({});
const mapDispatch = {setAccount, };
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & PropsFromUIHelpers;

interface State {
    showDlgBusy: boolean;
    dlgBusyTitle: string;
    dlgBusyMessage: string;
    showDlgAlert: boolean;
    dlgAlertTitle: string;
    dlgAlertMessage: string;
    username: string;
    password: string;
    acceptTOS: boolean;
}

class LoginPage extends React.Component<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            showDlgBusy: false,
            dlgBusyTitle: 'Busy',
            dlgBusyMessage: 'Please wait...',
            showDlgAlert: false,
            dlgAlertTitle: 'Notice',
            dlgAlertMessage: '',
            username: '',
            password: '',
            acceptTOS: false,
        };
    }

    alert(title:string, message:string) {
        this.setState({showDlgAlert:true, dlgAlertTitle:title, dlgAlertMessage:message});
    }

    showBusy(title:string, message:string='Please wait...') {
        this.setState({showDlgBusy:true, dlgBusyTitle:title, dlgBusyMessage:message});
    }

    hideBusy() { this.setState({showDlgBusy: false, }); }

    componentDidMount() { setTimeout(() => this.checkAutoLogin(), 100) }

    checkAutoLogin() {
        let cached = localStorage.getItem(Consts.STORAGE_ACCOUNT);
        if (cached) {
            let account = JSON.parse(cached);
            if (account.userid && account.username && account.password) {
                this.loginAccount(account);
            }
        }
    }

    loginAccount(account:Account) {
        CloudAPI.setCredit(account.username, account.password);
        this.props.setAccount(account);
        const target = (this.props.location.state && this.props.location.state.target) ? this.props.location.state.target : '/';
        this.props.navigate(target);
        //history.replace('/');
    }

    onSubmitForm(e:any) {
        console.log(e);
        e.preventDefault();
        if (!this.state.username || !this.state.password) {
            this.alert('Error', 'Please provide username and password.');
            return;
        }
        // if (!this.state.acceptTOS) {
        //     this.alert('Error', 'You should accept the Terms of Servide to continue.');
        //     return;
        // }
        this.showBusy('Signing in litemoment.com');
        CloudAPI.login(this.state.username, this.state.password).then((result:any) => {
            console.log(result);
            this.hideBusy();

            let account:Account = {
                userid: result['_id'],
                username:this.state.username, 
                password:this.state.password,
                createTS: result.createTS,
                updateTS: result.updateTS,
            };
            if (result.email) account['email'] = result.email;
            if (result.phone) account['phone'] = result.phone;

            localStorage.setItem(Consts.STORAGE_ACCOUNT, JSON.stringify(account));
            this.loginAccount(account);

        }).catch(err => {
            console.log(err.stack);
            this.hideBusy();
            let msg = CloudAPI.getErrString(err);
            this.alert('Cloud error', msg);
        });
    }

    onChangeText(e:any, field:string)  {
        let value:string = (e.target as HTMLInputElement).value;
        if (field === 'username') this.setState({username:value});
        else if (field === 'password') this.setState({password:value});
    }

    onChangeCheckbox(e:any, field:string) {
        let value = e.target.checked;
        if (field === 'acceptTOS') this.setState({acceptTOS:value,});
    }

    render() {
        return (
            <div style={{padding:20,}}>
                <div style={{maxWidth:600, minWidth:300, margin:'auto'}}>
                    <h1 style={{padding:20}}>Sign in</h1>
                    <div style={{textAlign:'left',}}>
                        <Form onSubmit={this.onSubmitForm.bind(this)}>
                        <Form.Group controlId="username">
                            <Form.Label>Login name</Form.Label>
                            <Form.Control type="text" placeholder="User name, email or phone number"
                                value={this.state.username}
                                onChange={(e:any) => {this.onChangeText(e, 'username');}}
                                />
                            <Form.Text className="text-muted">
                            Your personal information will be private to others.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password"
                                value={this.state.password}
                                onChange={(e:any) => {this.onChangeText(e, 'password');}}
                                />
                        </Form.Group>
                        <div className="d-flex justify-content-between">
                            <div>&nbsp;</div>
                            <div>
                                <Link to="/register">Sign up</Link>
                                &nbsp;or&nbsp;
                                <Link to="/reset">Reset password</Link> ?
                            </div>
                        </div>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        </Form>
                    </div>
                </div>
                <DialogBusyWait visiable={this.state.showDlgBusy}
                    title={this.state.dlgBusyTitle}
                    message={this.state.dlgBusyMessage}/>
                <DialogAlert visiable={this.state.showDlgAlert}
                    title={this.state.dlgAlertTitle}
                    message={this.state.dlgAlertMessage}
                    onOK={() => {this.setState({showDlgAlert:false})}}/>
            </div>
        );
    }
}

export default withUIHelpers(connector(LoginPage));
